/**
 * Create a new instance of CommonHttpService
 * @class CommonHttpService
 * @description
 * This service define a wrapper over http requests GET,POST,PUT,PATCH,DELETE
 */

import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs/internal/Observable';
import { JwtService } from './jwt.service';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import APIResponseModel from '../models/api-response-model';
import IApiBaseDataModel from '../interfaces/api-base-data-model';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonHttpService {

  //#region Constructors
  constructor(private http: HttpClient, private jwtService: JwtService) { }
  //#endregion

  //#region Functions

  //#region Private Functions
  private setHeaders(): HttpHeaders {
    const headersConfig: any = { 'Content-Type': 'application/json', Accept: 'application/json' };
    if (this.jwtService.getToken()) {
      headersConfig.Authorization = `Bearer ${this.jwtService.getToken()}`
      headersConfig.Device = 'Desktop';
    }
    return new HttpHeaders(headersConfig);
  }


  private formatErrors(err: any) {
    console.log('api call error:: ', err);
    return throwError(err?.error);
  }
  //#endregion

  //#region Public Functions
  public get<ResModel extends IApiBaseDataModel>(path: string, params: HttpParams = new HttpParams()):
    Observable<any> {
    return this.http.get(`${path}`, { headers: this.setHeaders(), params: params })
      .pipe(catchError(this.formatErrors))
      .pipe(map((res: Response) => res));
  }

  public put<ReqModel extends IApiBaseDataModel, ResModel extends IApiBaseDataModel>(path: string, body: any):
    Observable<APIResponseModel<ResModel>> {
    return this.http.put(`${path}`, body, { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors))
      .pipe(map((res: APIResponseModel<any>) => res));
  }

  public post<ReqModel extends IApiBaseDataModel, ResModel extends IApiBaseDataModel>(path: string, body: ReqModel):
    Observable<APIResponseModel<ResModel>> {
    return this.http.post(`${path}`, body, { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors), map((res: APIResponseModel<any>) => res));

  }

  public delete<ResModel extends IApiBaseDataModel>(path: string):
    Observable<any> {
    return this.http.delete(`${path}`, { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors))
      .pipe(map((res: Response) => res));
  }
  //#endregion

  //#endregion

  //#region Deprecated

  // get(path: string, params: URLSearchParams = new URLSearchParams()): Observable<any> {
  //   return this.http.get(`${environment.api_url}${path}`, { headers: this.setHeaders(), search: params }
  //   ).pipe(catchError(this.formatErrors)).pipe(map((res: HttpResponse<>) => res.json()));
  // }


  // put(path: string, body: Object = {}): Observable<any> {
  //   return this.http.put(
  //     `${environment.api_url}${path}`,
  //     JSON.stringify(body),
  //     { headers: this.setHeaders() }
  //   )
  //     .pipe(catchError(this.formatErrors))
  //     .pipe(map((res: Response) => res.json()));
  // }


  // post(path: string, body: Object = {}): Observable<any> {
  //   return this.http.post(
  //     `${environment.api_url}${path}`,
  //     JSON.stringify(body),
  //     { headers: this.setHeaders() }
  //   )
  //     .pipe(catchError(this.formatErrors))
  //     .pipe(map((res: Response) => res.json()));
  // }


  // delete(path: string, body: Object = {}): Observable<any> {
  //   return this.http.delete(`${environment.api_url}${path}`, new RequestOptions({
  //     headers: this.setHeaders(),
  //     body: JSON.stringify(body)
  //   }))
  //     .pipe(catchError(this.formatErrors))
  //     .pipe(map((res: Response) => res.json()));
  // }

  //#endregion

}
