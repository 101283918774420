<div class="profile-box-area mb-1">
    <div class="d-flex">
    <div class="profile-box-img mr-2">
       
        <img *ngIf="staffInfo.profileUrl!==' '" src={{staffInfo.profileUrl}} onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" alt="profile-image"/>
        <img *ngIf="staffInfo.profileUrl===' '" src="assets/images/person-ic.jpg" onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" alt="profile-image"/>
       
        <!-- <ng-container *ngIf="staffInfo.profileUrl"> <img src={{staffInfo.profileUrl}} /></ng-container>
        <ng-container *ngIf="!staffInfo.profileUrl"> <img src="assets/images/person-ic.jpg" /></ng-container> -->
    </div>
    <div class="profile-box-info">
        <h3>{{staffInfo.name}}</h3>
        <h5>{{staffInfo.branchName}}, {{staffInfo.branchLocation}}</h5>

        <label class="d-flex align-items-center w-100 icon-with-label">
            <span class="material-icons green_text mr-2">
                email
            </span>
            <span class="mail-text-wrap">
                {{staffInfo.emailId}}
            </span>
        </label>
        <label class="d-flex align-items-center w-100 icon-with-label">
            <span class="material-icons green_text mr-2">
                phone
            </span>
            {{staffInfo.phoneNo}}
        </label>
    </div>
    </div>
</div>