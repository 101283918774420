import { Pipe, PipeTransform } from '@angular/core';
import { eUnit } from '@app/modules/shipper/enums/shipper-enum';

@Pipe({
    name: 'WeightKgToLbs'
})
export class WeightKgToLbs implements PipeTransform {
    transform(weight: number, unit: eUnit): string {
        let convertedWeight: number;
        if (unit === eUnit.KG) {
            convertedWeight = weight * 2.205;
        } else {
            convertedWeight = Number(weight);
        }
        return convertedWeight.toFixed(2) + ' Lbs';
    }
}