/*
* @ngdoc Service
* @name app.services.UserService
* @description
* This service is used for handling user related functions
*/

import { Injectable } from '@angular/core';
import { CommonHttpService } from './common-http.service';
import { appApiUrl } from '@configs/app-api-urls.config';
import { Observable } from 'rxjs';
import APIResponseModel from '../models/api-response-model';

interface  UserLogin {
  email: string;
  password: string;
  remember_me?: boolean;
}

// interface CheckEmail {
//   email: string;
// }

interface RoleAttribute {
  RoleId: string
}

export interface SignUp {
  RoleId: number;
  FirstName: string;
  LastName: string;
  Status: boolean;
  Email: string;
  CompanyName: string;
  CompanyAddress: string;
  PropertyName: string;
  PropertyAddress: string;
  PropertyTypes: any[];
  ServiceTypes: any[];
  Phone: string;
  Password: string;
}


@Injectable()
export class UserService {
  public startedEmail: string;

  constructor(private commonHttpService: CommonHttpService) { }

  /**
   * @function login
   * @description
   * Making a user login request
   * @param param {UserLogin}
   * @returns {Observable<ApiResponseModel>}
   */
  login(param: UserLogin): Observable<APIResponseModel<any>> {
    const route = 'user/login';
    
    return this.commonHttpService.post(route, param);
  }

  /**
   * @function checkEmail
   * @description
   * Making a check email request
   * @param param {checkEmail}
   * @returns {Observable<ApiResponseModel>}
   */
  checkEmail(param: any): Observable<APIResponseModel<any>> {
    return this.commonHttpService.post(appApiUrl.auth.checkEmail, param);
  }

  /**
   * Update started email
   * @param Email param
   */
  updateStartedEmail(Email) {
    this.startedEmail = Email;
  }

  /**
   * @function get Roles
   * @description
   * Making a get roles request
   * @returns {Observable<ApiResponseModel>}
   */
  getRoles(): Observable<APIResponseModel<any>> {
    return this.commonHttpService.get(appApiUrl.users.role);
  }

  /**
   * @function getRoleAttribute
   * @description
   * Making a check email request
   * @param param {RoleId}
   * @returns {Observable<ApiResponseModel>}
   */
  getRoleAttribute(param: RoleAttribute): Observable<APIResponseModel<any>> {
    return this.commonHttpService.post(appApiUrl.attribute, param);
  }

  /**
   * @function login
   * @description
   * Making a user login request
   * @param param {UserLogin}
   * @returns {Observable<ApiResponseModel>}
   */
  signUp(param: SignUp): Observable<APIResponseModel<any>> {
    return this.commonHttpService.post(appApiUrl.auth.signup, param);
  }

}
