
<section *ngIf="_accessToken && _planId" class="login text-center">
    <div>
        <div class="container-inner mb-6">
            <div class="px-3 py-3 w-100">
                <div class="col d-flex justify-content-between align-items-center login-head">
                    <img src="assets/images/logo.svg" alt="">
                    <div>Is there any trouble? <a href="">Contact support</a></div>
                </div>
            </div>
        </div>

        <div class="login-content-wrapper mx-auto">

            <div class="heading-with-back-arrow">
                <div class="back-annual-plan mt-0 mb-4">
                    <!-- <span (click)="onRedirectToPlans()" routerLinkActive="router-link-active" class="material-icons">
                    keyboard_backspace
                </span> -->
                </div>

                <div class="d-flex align-items-center mb-0 w-100">
                    <div class="w-100">
                        <p class="heading text-center w-100 mb-4">Card Details </p>
                    </div>
                </div>
            </div>

            <form novalidate (ngSubmit)="createToken()" [formGroup]="stripeTest">
                <input type="hidden" formControlName="name">
                <div class="login-content login-box box_shodow stripe-card-box">
                    <ngx-stripe-card [options]="cardOptions" class="stripe-card" [elementsOptions]="elementsOptions"
                    (change)="onCardChange($event)">
                    </ngx-stripe-card>
                    <div *ngIf="!_processingPayment && !_paymentSuccessfull" class="rounded text-center mb-4">
                        <button [disabled]="!isCardValid" (click)="createToken()" class="btn large_btn rounded_btn green_btn w-100" mat-flat-button color="primary" type="button">
                            Pay
                        </button>
                    </div>
                    <div *ngIf="_processingPayment" _ngcontent-rae-c1="" class="form_row p-relative">
                        <button class="btn large_btn rounded_btn green_btn w-100" mat-flat-button color="primary" type="button">
                            Processing payment
                        </button>
                        
                    </div>
                    <div *ngIf="_paymentSuccessfull" class="rounded text-center mb-4">
                        <button class="btn large_btn rounded_btn green_btn w-100" mat-flat-button color="primary" type="button">
                            Payment Successfull
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>