<div mat-dialog-title class="position-relative px-4 py-3 mb-0 title-border">
    <h2 class="mb-0">Job detail <span class="f-12 fw-400 text-grey-light">(LYNK ID#: {{data.lynk}})</span></h2>
    <span class="material-icons position-absolute close-icon-modal" (click)="close()">close</span>
</div>
<div mat-dialog-content class="content-modal">
    <div class="mb-0 mt-3">
        <div class="row mb-3">
            <div class="col-sm-12">

                <!-- <div class="avatar-div"><img src="assets/images/avatar.jpg" alt=""></div> -->
                <div class="d-flex flex-wrap align-item-start justify-content-between heading-with-price">
                    <div class="heading">
                        <h3 class="mb-0 fw-500 black bid-pop-heading">{{data.postedBy.name}}</h3>
                        <p class="f-12 text-grey-medium m-0">posted on: {{data.postedOn}}</p>
                        <div class="d-flex arrow-wrapper">
                            <span *ngFor="let iDetail of data.details; let i = index;">
                                <h3 class="mb-0 fw-500 black bid-pop-heading">
                                    {{iDetail.addressId.city}}, {{iDetail.addressId.country}}
                                    <span *ngIf="i < data.details.length-1" class="arrow-img">
                                        <img src="assets/images/two-arrow-head.svg" alt="">
                                    </span>
                                </h3>
                            </span>
                        </div>
                    </div>
                    <div>
                        <!-- <p class="f-15">Rate: <span class="fw-500 green_text">{{data.authoHire ? data.targetPrice : data.bidDetail.amount}}</span></p> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="row">

                    <div class="col-md-4 col-sm-6 d-flex mb-2"><span class="fw-500 f-12 text-grey-medium"><strong
                                class="fw-500 d-black">Service
                                type:</strong>{{data.loadServiceType == 1 ? 'Full' : "Partial"}}</span></div>
                    <div class="col-md-4 col-sm-6 d-flex mb-2"><span class="fw-500 f-12 text-grey-medium"><strong
                                class="fw-500 d-black">Total Weight:</strong> {{data.totalWeight}}
                            {{data.unit == eUnit.KG ? 'Kg': 'Lbs'}}</span>
                    </div>
                    <!-- <div class="col-md-4 col-sm-6 d-flex mb-2"><span class="fw-500 f-12 text-grey-medium"><strong
                                class="fw-500 d-black">Customer reference:</strong> {{_referenceNumbers[0]}}
                            <span class="badge-label bg-green"
                                *ngIf="_referenceNumbers.length>1">+{{_referenceNumbers.length-1}}</span></span>
                    </div> -->

                    <div class="col-md-4 col-sm-6 d-flex mb-2"><span class="fw-500 f-12 text-grey-medium"><strong
                                class="fw-500 d-black">Trailer Type:</strong> {{data.trailerTypeId.type}}</span></div>
                    <div class="col-md-4 col-sm-6 d-flex mb-2"><span class="fw-500 f-12 text-grey-medium"><strong
                                class="fw-500 d-black">Type of Pickup:</strong>
                            {{data.pickupDropoffType == 1 ? 'Single Stop': 'Multiple Stop'}}</span></div>
                    <div class="col-md-4 col-sm-6 d-flex mb-2"><span class="fw-500 f-12 text-grey-medium"><strong
                                class="fw-500 d-black">No. of bids:</strong> {{data.noOfBids}}</span></div>

                    <div class="col-md-4 col-sm-6 d-flex mb-2"><span class="fw-500 f-12 text-grey-medium"><strong
                                class="fw-500 d-black">Trailer size:</strong> {{data.trailerSizeId.size}}</span></div>
                    <div class="col-md-4 col-sm-6 d-flex mb-2"><span class="fw-500 f-12 text-grey-medium"><strong
                                class="fw-500 d-black">Product Hazmat:</strong>
                            {{data.productHazmat == 'true' ? 'Yes' : 'No'}}</span></div>
                    <div class="col-md-4 col-sm-6 d-flex mb-2"><span class="fw-500 f-12 text-grey-medium"><strong
                                class="fw-500 d-black">Shipment value:</strong> ${{data.loadValue}}</span></div>


                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 my-3">
                <hr>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-content class="content-modal">
    <div class="mb-4 mt-3">
        <div class="d-flex flex-wrap align-item-start justify-content-between heading-with-price">
            <div>
                <h3 class="mb-0 fw-500 black bid-pop-heading">Driver Information</h3>
                <br> 
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="row">

                        <div class="col-md-4 col-sm-6 d-flex mb-2"><span class="fw-500 f-12 text-grey-medium"><strong
                                    class="fw-500 d-black">Driver Name:</strong> {{this._driverName}}</span></div>
                        <div class="col-md-4 col-sm-6 d-flex mb-2"><span class="fw-500 f-12 text-grey-medium"><strong
                                    class="fw-500 d-black">Trailer Type:</strong> {{this._trailerType}}</span></div>
                        <div class="col-md-4 col-sm-6 d-flex mb-2"><span class="fw-500 f-12 text-grey-medium"><strong
                                    class="fw-500 d-black">Trailer Size:</strong> {{this._trailerSize}}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="_showData || _showData2 ; else AcceptOrDecline">
        <div mat-dialog-actions class="justify-content-center">
            <button *ngIf="!bookNow"
                class="mat-stroked-button disabled"
                type="submit">Trailer Type And Size Are Not Available</button>
            <button *ngIf="bookNow"
                class="f-18 btn green_btn fw-500 min-width-btn mr-3"
                type="submit" (click)="onSubmit()"><i class="f-18 material-icons icon-left mr-2">check_circle</i>
                Book Now</button>
                <button *ngIf="_showData2 && bookNow" class="f-18 btn red_btn fw-500 min-width-btn mr-3 mt-2"
                type="submit" (click)="openOfferDecline()"><i class="f-18 material-icons icon-left mr-2">cancel</i>
                Decline</button>
        </div>

    </ng-container>
    <ng-template #AcceptOrDecline>
        <div mat-dialog-actions class="justify-content-center">
            <button class="f-18 btn sml_rounded_btn large_btn huge-btn mx-300 green_btn fw-500 min-width-btn mr-3"
                type="submit" (click)="openOfferAccept()"><i class="f-18 material-icons icon-left mr-2">check_circle</i>
                Accept</button>
            <button class="f-18 btn sml_rounded_btn large_btn huge-btn mx-300 red_btn fw-500 min-width-btn"
                type="submit" (click)="openOfferDecline()"><i class="f-18 material-icons icon-left mr-2">cancel</i>
                Decline</button>
        </div>

    </ng-template>