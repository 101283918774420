import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { eOfferAcceptDecline } from '@app/modules/carrier/enums/offers-accepted.enum';
import { OfferAcceptDecline } from '@app/modules/solo-driver/models/offer-accept-model';
import { OfferAcceptService } from '@app/modules/solo-driver/service/offer-accept.service';
import { BgRejectDialougeComponent } from '../../bg-reject-dialouge/bg-reject-dialouge.component';

@Component({
  selector: 'app-decline-offer',
  templateUrl: './decline-offer.component.html',
  styleUrls: ['./decline-offer.component.scss']
})
export class DeclineOfferComponent implements OnInit {

  //#region  members 
  _offerDeclined: boolean = false;
  _reason:string = undefined;

  //#endregion

  //#region Life cycle hooks
  constructor(public dialogRef: MatDialogRef<DeclineOfferComponent>,
    public dialog: MatDialog,
    private _offerAcceptService: OfferAcceptService,
    @Inject(MAT_DIALOG_DATA) public data: any, ) { }

  ngOnInit(): void {
  }

  //#endregion

  //#region private functions

  //#endregion

  //#region Helper functions 
  openBidRejectDialouge(): void {
    this.dialog.open(BgRejectDialougeComponent, {
      width: '560px',
      data: this.data.lynk,
    });
    this.close();
  }

  close(): void {
    this.dialogRef.close(this._offerDeclined);
  }


  //#endregion

  //#region events 
  onSubmit() {
    const offerAcceptDecline = new OfferAcceptDecline()
    offerAcceptDecline.offerId = parseInt(this.data.offerId)
    offerAcceptDecline.acceptOrDecline = parseInt(eOfferAcceptDecline.Decline)
    offerAcceptDecline.reason = this._reason

    this._offerAcceptService.offerAdd(offerAcceptDecline).subscribe(res => {
      if (res) {
        this._offerDeclined = true
        this.close();
        this.openBidRejectDialouge()
      }
    })
  }
}







