import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _localStorageService: LocalStorageService,
    private _router: Router) {}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const authToken = this._localStorageService.getValueByKey('userToken');
    const isTokenExpired = (token: string) => {
      return Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000;
    };

    if (authToken && !isTokenExpired(authToken)) {
      return true;
    } else {
      this._router.navigate(['account/login']);
      this._localStorageService.deleteValueByKey('userToken');
      return false;
    }
  }
}
