import { Injectable } from '@angular/core';
import { CommonHttpService } from '@app/core/services/common-http.service';
import { Observable } from 'rxjs';
import { TrailerModel } from '../models/trailer.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrailerService {
  private route = 'solodriver/add-trailer';

  constructor( private commonHttpService: CommonHttpService) { }

  public add(argTrailerModel: TrailerModel): Observable<any> {
    return this.commonHttpService.post<TrailerModel, TrailerModel>(`${this.route}`, argTrailerModel);
  }

  public get(argTrailerId: string): Observable<any> {
    return this.commonHttpService.get<any>(`${'solodriver/trailer'}/${argTrailerId}`);
  }

  public getTrailer(): Observable<any> {
    return this.commonHttpService.get<any>(`${'solodriver/trailer'}`);
  }

  public update(argTrailerModel: TrailerModel): Observable<any> {
    return this.commonHttpService.put<TrailerModel, TrailerModel>(`${'solodriver/trailer'}/${argTrailerModel.id}`, argTrailerModel);
  }

  public delete(argTrailerId: string): Observable<any> {
    return this.commonHttpService.delete(`${'solodriver/trailer'}/${argTrailerId}`);
  }

  public getTrailers(trailerTypeId: number, trailerSizeId: number): Observable<any> {
    let  params = new HttpParams();
    params = params.append('trailerTypeId', `${trailerTypeId}`).set('trailerSizeId', `${trailerSizeId}`);

    return this.commonHttpService.get<any>(`${'solodriver/get-trailer'}`, params);
  }

}
