<div mat-dialog-title class="position-relative px-4 py-3 mb-0 title-border">
    <div class="header-wrap">
        <h2 class="mb-0">Decline Offer</h2>
        <span class="f-12 fw-400 text-grey-light">(LYNK ID#: {{data.lynk}})</span>
    </div>
    <span class="material-icons position-absolute close-icon-modal" (click)="close()">close</span>
</div>
<div mat-dialog-content class="content-modal">
    <div class="mb-0 mt-3">

        <div class="form_row">
            <label class="label mb-2">Add reason</label>
            <div class="input_block">
                <div class="input_hover input-border">
                    <mat-form-field class="w-100">
                        <textarea [(ngModel)]="_reason" class="input_fld md-textarea" matInput placeholder="Enter the description here..."></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer mt-3 mb-3 no-border">
    <button mat-button class="btn transparent_btn" (click)="close()">
      <i class="f-18 material-icons start">close</i>
      Cancel
  </button>
    <button mat-button class="btn sml_rounded_btn green_btn ml-2" (click)="onSubmit()">
      Submit <i class="f-18 material-icons rotate-right icon-right">keyboard_backspace</i>
  </button>
</div>