import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialAdminGuard implements CanActivate {
  constructor(private _localStorageService: LocalStorageService,
    private _router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this._localStorageService.getValueByKey('userType') == '7' || this._localStorageService.getValueByKey('userType') == '9' || this._localStorageService.getValueByKey('userType') == '11') {
      return true
    }

    else {
      this._router.navigate(['account/login']);
      return false
    }
  }
  
}
