<div mat-dialog-content class="position-relative">
  <div class="text-center mb-0 mt-3">
    <span class="material-icons position-absolute close-icon-modal" (click)="closeDialog()">close</span>
    <img src="assets/images/check-icon.svg" alt="" class="mb-3">
    <h2 class="mb-2 fw-400 f-22">{{this.data.title}}</h2>
    <div>
      <p class="f-16 pb-0" [innerHTML]="this.data.description"></p>
    </div>


  </div>
</div>
<div mat-dialog-actions class="d-flex align-center justify-content-center my-1">
  <button (click)="onClickYes()" mat-button class="btn rounded_btn green_btn mr-2 small_btn px-2"> Yes</button>
  <button (click)="onClickNo()" mat-button class="btn rounded_btn grey_border_btn ml-2 small_btn px-2">No</button>
</div>