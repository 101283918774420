import { Component, OnInit } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
   _loaderRequestQueue = new Array<string>();
  constructor(
    private _commonService: CommonService
  ) { }

  ngOnInit(): void {

    //Loader request handling
    this._commonService.setPageLoadingStatus().subscribe(res => {
      if (res !== undefined) {
        const that = this;
        setTimeout(function () {
          const index = that._loaderRequestQueue.findIndex(iRequestId => iRequestId === res.Identifier)
          if (res.IsLoading) {
            if (index < 0) {
              that._loaderRequestQueue.push(res.Identifier)
            }
          }
          else if (index > -1) {
            that._loaderRequestQueue.splice(index, 1)
          }
        })
      }
    })
  }
}



