import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ShipperGuard implements CanActivate {
  constructor(private _localStorageService: LocalStorageService,
    private _router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      if (this._localStorageService.getValueByKey('userType') == '2' ||
      this._localStorageService.getValueByKey('userType') == '6' ||
      this._localStorageService.getValueByKey('userType') == '8' ) {
        return true;
      } else {
        this._router.navigate(['account/login']);
        return false;

      }
    }
}
