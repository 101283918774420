<div class="mat-dialog-container">
  <div class="popup-header">
    <h2 class="popup-title">Reference Number Details</h2>
    <span (click)="close()" class="close-button">
      <mat-icon aria-label="Close">close</mat-icon>
    </span>
  </div>
  
  <div class="popup-content" style="max-height: 120px; overflow-y: auto;">
    <ul>
      <li *ngFor="let reference of data.reference_number.split(',')" class="reference-item">
       <span>
        {{ reference.trim() }}
       </span>
      </li>
    </ul>
  </div>
</div>