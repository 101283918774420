<div mat-dialog-content class="position-relative">
    <div class="text-center mb-0 mt-3">
        <span class="material-icons position-absolute close-icon-modal" (click)="closeDialog()">close</span>
        <img src="assets/images/check-icon.svg" alt="" class="mb-3">
        <h2 class="mb-2 fw-400 f-22">{{this.data.title}}</h2>
        <!-- <p class="f-16 pb-0"> {{this.data.description}}</p> -->
        <div>
            <p class="f-16 pb-0" [innerHTML]="this.data.description"></p>
        </div>


    </div>
</div>
<div mat-dialog-actions class="d-flex align-center justify-content-center my-1">
    <button *ngIf="userType!=5"(click)="onSelectAutohire()" mat-button class="btn rounded_btn green_btn mr-2  px-2"> Get Autohired</button>
    <button *ngIf="userType==5" (click)="onAuthoried()" mat-button class="btn rounded_btn green_btn mr-2  px-2"> Get Autohired</button>
    <button (click)="onSelectBid()" mat-button class="btn rounded_btn green_btn px-4">Apply Bid</button>
</div>