<div class="profile-box-area">
    <div class="d-flex">
    <div class="profile-box-img mr-2">
        <img *ngIf="carrierInfo.logoUrl!==' '" src={{carrierInfo.logoUrl}} onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" alt="profile-image"/>
        <img *ngIf="carrierInfo.logoUrl===' '" src="assets/images/person-ic.jpg" onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" alt="profile-image"/>


    </div>
    <div class="profile-box-info">
        <h3>{{carrierInfo?.companyName}}</h3>
       
        <div [ngClass]="{'active': carrierInfo?.isFavorite}" class="like-area">
            <span class="material-icons" (click)="onMarkFavourite(carrierInfo)">
                favorite_border
            </span>
        </div>
        <div>
            <star-rating #ratingChange value="{{carrierInfo?.rating}}" totalstars={{totalstar}} checkedcolor="#51af57"
                uncheckedcolor="#ddd" size="24px" readonly='true'></star-rating>

        </div>


        <div class="reviews-number">{{carrierInfo?.rating}} Ratings & {{carrierInfo?.reviews}} Reviews</div>
        <label *ngIf="bidRate != null"><strong>Bid Rate</strong>: ${{bidRate}}</label>
        <p>{{carrierInfo?.memberSince}}</p>
    </div>
    </div>
</div>