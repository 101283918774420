import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { eOfferAcceptDecline } from '@app/modules/carrier/enums/offers-accepted.enum';
import { CommonService } from '@app/core/services/common.service';
import { TruckService } from '@app/modules/solo-driver/service/truck.service';
import { TrailerService } from '@app/modules/solo-driver/service/trailer.service';
import { LoadService } from '@app/modules/solo-driver/service/load.service';
import { DriverService } from '@app/modules/solo-driver/service/driver.service';
import { OfferAcceptService } from '@app/modules/solo-driver/service/offer-accept.service';
import { GetHiredDetail } from '@app/modules/solo-driver/models/get-hired.model';
import { OfferAcceptDecline } from '@app/modules/solo-driver/models/offer-accept-model';
import { OfferConfirmationComponent } from '../offer-confirmation/offer-confirmation.component';

@Component({
  selector: 'app-assign-truck',
  templateUrl: './assign-truck.component.html',
  styleUrls: ['./assign-truck.component.scss']
})
export class AssignTruckComponent implements OnInit {


  //#region members
  _truckList = new Array<any>();
  _trailerList = new Array<any>();
  _driverList = new Array<any>();
  _selectedDriver = new SelectedDriver();
  _offerAccepted: boolean = false;
  _isSubmitted: boolean = false;
  showMessage = false;
  //#endregion

  @ViewChild('autoCompleteDrivers') autoCompleteDrivers: MatAutocomplete;



  //#region form
  _form: FormGroup = this._formBuilder.group({
    truckId: new FormControl('', Validators.required),
    trailerId: new FormControl('', Validators.required),
    driverId: new FormControl('', Validators.required),
    // description: new FormControl('')
  });

  //#endregion

  //#region Life cycle Hooks
  constructor(public dialogRef: MatDialogRef<AssignTruckComponent>,
    public dialog: MatDialog,
    private _truckService: TruckService,
    private _trailerService: TrailerService,
    private _loadService: LoadService,
    private _driverService: DriverService,
    private _commonService: CommonService,
    private _formBuilder: FormBuilder,
    private _offerAcceptService: OfferAcceptService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    
  }

  ngOnInit(): void {
    this.fetch();
    this.onSearchDrivers();
  }


  //#endregion

  //#region  events
  onSearchDrivers() {

    if (!this._selectedDriver.name) {
      this._form.get('driverId').valueChanges
        .subscribe(queryField => {
          if (queryField.length >= 1) {
            this._driverService.search(queryField).subscribe(res => {
              if (res.data) {
                this._driverList = res.data;
              }
            });
          }
        });
    }

  }

  onSubmit() {
    if (this.data.autoHire) {
      this._isSubmitted = true;
      if (this._form.valid) {
        const getHiredModel = new GetHiredDetail();
        getHiredModel.loadId = parseInt(this.data.id);
        getHiredModel.vehicleId = this._form.get('truckId').value;
        getHiredModel.trailerId = this._form.get('trailerId').value;
        // getHiredModel.driverId = this._selectedDriver.id;
        this._loadService.getHired(getHiredModel).subscribe(res => {
          if (res.data) {
            this.openOfferConfirmation(this.data);
          }
          else {
            this._commonService.showSnakeBar(res.message);
          }
        }, (error) => {
          this._commonService.showSnakeBar(error.message);
        });
      }
      else {
        this._commonService.showSnakeBar("Please fill all the mandatory fields!");
      }
    }
    else {
      this._isSubmitted = true;
      if (this._form.valid) {
        const offerAcceptDecline = new OfferAcceptDecline();
        offerAcceptDecline.offerId = parseInt(this.data.offerId);
        // offerAcceptDecline.vehicleId = this._form.get('truckId').value;
        // offerAcceptDecline.trailerId = this._form.get('trailerId').value;
        // offerAcceptDecline.driverId = this._selectedDriver.id;
        offerAcceptDecline.acceptOrDecline = parseInt(eOfferAcceptDecline.Accept);
        this._offerAcceptService.offerAdd(offerAcceptDecline).subscribe(res => {
          if (res.data) {
            this._offerAccepted = true;
            this.openOfferConfirmation(this.data);
            this.close();
          }
          else {
            this._commonService.showSnakeBar(res.message);
          }
        }, (error) => {
          this._commonService.showSnakeBar(error.message);
        });
      }
      else {
        this._commonService.showSnakeBar('Pleae fill all the mandatory fields!');
      }
    }
  }

  //#endregion


  fetch() {
    this.getTruckList();
    this.getTrailerList(this.data.trailerTypeId.id, this.data.trailerSizeId.id);
  }


  //#region Private methods 
  private getTruckList() {
    this._truckService.getVechile().subscribe(res => {
      if (res.data) {
        this._truckList = res.data.Vechile;
      }
    });
  }

  private getTrailerList(trailerTypeId, trailerSizeId) {
    this._trailerService.getTrailers(trailerTypeId, trailerSizeId).subscribe(res => {
      if (res.data) {
        this._trailerList = res.data;
        this.showMessage = res.data.length === 0 ;
      }
    },()=>{
      
    });
  }


  selectDriver(argDriver: any) {
    this._selectedDriver.name = argDriver.name;
    this._selectedDriver.id = argDriver.id;

  }

  removeDriver() {
    this._selectedDriver = null;
  }
  //#endregion

  //#region Helper methods
  openOfferConfirmation(argLoadData: any): void {
    this.dialog.open(OfferConfirmationComponent, {
      width: '692px',
      data: argLoadData
    });
    this.close();
  }


  close(): void {
    this.dialogRef.close(this._offerAccepted);
  }

  //#endregion

}
export class SelectedDriver {
  id: number = undefined;
  name: string = undefined;
}
