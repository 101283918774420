import { HttpClient } from "@angular/common/http";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  OnDestroy,
  ChangeDetectorRef
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonService } from "@app/core/services/common.service";
import { environment } from "@environment/environment";
import * as Pubnub from 'pubnub';

@Component({
  selector: "app-pubnub-chat",
  templateUrl: "./pubnub-chat.component.html",
  styleUrls: ["./pubnub-chat.component.scss"],
})
export class PubnubChatComponent implements OnInit, AfterViewChecked, OnDestroy {

  @ViewChild("scrollMe") myScrollContainer: ElementRef;
  @ViewChild('inputFile') myInputVariable: ElementRef;
  scrolltop: number = null;
  private pubnubService: Pubnub
  public channel: string;
  sender: any;
  applyClass = false;
  ChannleData: any;
  openModel: boolean;
  isLoading:boolean=false;
  _OnScroll:boolean=true
  currentMessageList:any
  getMessage:any
  //#region document members 
  _isFile: boolean;
  _Document: File = null;
  _selectedFile: File = undefined;
  _DocumentUrl: string;
  _DocumentKey: string = undefined;
  _DocumentName: string = undefined;
  _fileUploaded: boolean = false;
  _fileUploading: boolean = false;
  _fileTypeErrorMessage: string = '';
  _fileSizeErrorMessage: string = '';
  _documentType: string
  
  constructor(
    public dialogRef: MatDialogRef<PubnubChatComponent>,
    private changeDetector: ChangeDetectorRef,
    private _commonServices: CommonService,
    private _http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.channel = `${data.channel}x`;
    this.sender = data.senderInfo;
  }

  ngOnInit() {
    this.pubnubService = new Pubnub({
      publishKey: environment.pubnubPublishKey,
      subscribeKey: environment.pubnubSubscribeKey,
      userId: Pubnub.generateUUID(),
    });
    this.pubnubService.subscribe({
      channels: [this.channel],
      withPresence: true,
    });

    this.openModel = true
    this.addListner()
    this.fetchMessage()
  }

  addListner(){
    this.pubnubService.addListener({
      message: ((message) => {
        this.currentMessageList.map((getMessage)=>{
          this.getMessage = getMessage
        })
        this._OnScroll = true
        const payLoad = {
          date: message.message.date,
          channels: [message]
        }
        if(!this.getMessage){
          this.currentMessageList.push(payLoad)
        }else if(this.getMessage.date == message.message.date){
          this.getMessage.channels.push(message)
        }else{
          this.currentMessageList.push(payLoad)
        }
          this.addMessageAction(message.timetoken, message.message.sender)
        }),
      messageAction: ((messageAction) => {
        this.currentMessageList.map((messageArray)=>{
          messageArray.channels.map((action)=>{
            action['actions'] = messageAction.data
            })
          })
      }),
    })
  }

  ngAfterViewChecked() {
    if(this._OnScroll == true){
      this.scrollToBottom();
    }
  }

  ngOnDestroy() {
    this.pubnubService.unsubscribe({
      channels: [this.channel]
    });
  }
  scrollToBottom(): void {
    this.scrolltop = this.myScrollContainer?.nativeElement?.scrollHeight;
    this.changeDetector?.detectChanges();
  }

  onScroll(){
    setTimeout(()=>{
      this.retrieveOldMessages()
      this._OnScroll = false
    },200)
  }

  fetchMessage() {
    this.pubnubService.fetchMessages({
      channels: [this.channel],
      includeUUID: true,
      includeMessageActions: true,
    },(status, responce) => {
      if (status.statusCode === 200) {
        let chatData = []
        const messageRes = responce.channels[this.channel] === undefined ? chatData : responce.channels[this.channel]
        this.ChannleData = messageRes;
        this.getGroupByData(this.ChannleData)
        this.ChannleData.forEach((messageActions) => {
          if (!messageActions.actions) {
            this.addMessageAction(messageActions.timetoken, messageActions.message.sender)
          }
        })
      }
    })
  }

  retrieveOldMessages(){
    this.isLoading=true;
    this.pubnubService.fetchMessages({
      channels: [this.channel],
      start: this.ChannleData[0].timetoken,
      includeMessageActions: true
    },(status, res)=>{
      if(status.statusCode == 200){
        res.channels[this.channel].reverse().forEach((a)=>{
         this.ChannleData.unshift(a)
          this.getGroupByData(this.ChannleData)
         this.ChannleData.map((messageActions) => {
          if (!messageActions.actions) {
            this.addMessageAction(messageActions.timetoken, messageActions.message.sender)
          }
        })
      })
      }
      this.isLoading=false
    })
  }
  
  getGroupByData(getMessage) {
    const messages = getMessage.reduce((messages, chat) => {
      const date = chat.message.date
      if (!messages[date]) {
        messages[date] = [];
      }
      messages[date].push(chat);
      return messages;
    }, {});
    const getMessages = Object.keys(messages).map(date => {
      return {
        date,
        channels: messages[date]
      };
    });
    this.currentMessageList = getMessages
  }

  addMessageAction(timetoken: string, senderName: any) {
    if (this.openModel == true && senderName.company != this.sender.company) {
      this.pubnubService.addMessageAction({
        channel: this.channel,
        messageTimetoken: timetoken,
        action: {
          type: 'receipt',
          value: this.sender.company,
        },
      });
    }
  }


  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  isFile(filename) {
    var ext = this.getExtension(filename);
    this._documentType = ext
    switch (ext.toLowerCase()) {
      case 'pdf':
      case 'docx':
      case 'jpg':
      case 'png':
      case 'jpeg':
        return true;
    }
    return false;
  }

  onRemoveFile() {
    this._fileUploaded = false;
    this._fileUploading = false;
    this._Document = null;
    this._DocumentUrl = '';
    this._DocumentName = '';
    this._documentType = '';
    this.myInputVariable.nativeElement.value = '';
  }
  onFileSelected(event) {
    this._Document = <File>event.target.files[0];
    if (this._Document) {
      // Check the size of Selected File File size should not exceed than 5 MB
      if (this._Document.size < 5242880) {
        this._isFile = this.isFile(this._Document.name);
        /**Check if image is of type JPG , JPEG or PNG */
        if (this._isFile) {
          this._fileTypeErrorMessage = '';
          this._fileSizeErrorMessage = '';
          this.uploadFile();
        }
        else {
          this._fileTypeErrorMessage = "Please upload a file of type PDF, DOCX, JPG, PNG, or JPEG";
          this._Document = null;
        }
      }
      else {
        this._fileSizeErrorMessage = "Max Size of File should be 5MB";
        this._Document = null;
      }
    }
  }
  uploadFile() {
    this._fileUploaded = false;
    this._fileUploading = true;
    const fd = new FormData();
    fd.append('file', this._Document);
    this._http.post('master/upload-file', fd).subscribe((res: any) => {
      this._fileUploading = false;
      this._fileUploaded = true;
      this._DocumentKey = res.data.key;
      this._DocumentUrl = res.data.signedUrl;
      this._DocumentName = this._Document.name;
    }, (error) => {
      this._fileUploading = false;
      this._commonServices.showSnakeBar(error.message)
    });
  }


  sendMessage(message: string) {
    if (message.trim() !== "" || this._fileUploaded == true) {
      this.pubnubService.publish({
        channel: this.channel,
        ttl: 0,
        message: {
          sender: this.sender,
          message,
          date: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
          dateTime: Date.now().toString(),
          file: {
            documentKey: this._DocumentKey,
            documentName: this._DocumentName,
            documentUrl: this._DocumentUrl,
            documentType: this._documentType
          }
        },
      });
    }
    this.onRemoveFile()
  }

  getLength(message: string) {
    this.applyClass = message.length > 0;
  }
  closeChatBox() {
    this.dialogRef.close('save');
    this.openModel = false
  }
}
