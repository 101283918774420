<div *ngIf="data" class="profile-box-area">
    <div class="d-flex">
    <div class="profile-box-img mr-2">
        <img *ngIf="data?.logoUrl!==' '" src={{data.logoUrl}} onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" alt="profile-image"/>
        <img *ngIf="data.logoUrl===' ' " src="assets/images/person-ic.jpg" onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" alt="profile-image"/>
    </div>
    <div class="profile-box-info">
        <h3>{{data.companyName}}</h3>
        <div>
            <star-rating #ratingChange value="{{data?.rating}}" totalstars={{totalstar}} checkedcolor="#51af57"
                uncheckedcolor="#ddd" size="24px" readonly='true'></star-rating>
        </div>
        <div class="reviews-number">{{data.rating}} Ratings & {{data.reviews}} Reviews</div>
        <!-- <p>Member Since: {{data.memberSince}}</p> -->
    </div>
    </div>
</div>