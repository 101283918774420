<div *ngIf="this.context" class="d-flex align-items-center page_header_right">
  <button *ngIf="this.context._filter == true" class="btn rounded_btn border_btn small_btn mr-3" (click)="reload()">
    <!-- <span class="filter-badge mr-2">3</span> -->
    <span class="filter-text mr-1">Clear Filter</span>
    <span class="material-icons cursor-pointer">close</span>
  </button>

  <i style="cursor:pointer;" class="material-icons icon-left" matTooltip="Filter" [matTooltipDisabled]="showhide" (click)="filltertoggle()"
    [ngClass]="{ open: showhide }">tune</i>
</div>
<div class="filter-section" [ngClass]="{ open: showhide }">
  <div class="filter-inner-wrapper">
  <div class="filter-header">
    <div class="d-flex justify-content-between">
      <p class="font-20 m-0">Filter</p>
      <div class="filter-iconBox">
        <span class="material-icons refresh-filter" (click)="reset()">refresh</span>
        <span class="material-icons close-filter" (click)="close()" [ngClass]="{ open: showhide }">close</span>
      </div>
    </div>
  </div>

  <div class="filter-content">
    <form [formGroup]="_form">
      <div *ngIf="name == 'loads' || name == 'all-loads'">
        <label class="label mb-2">Age of job</label>
        <div class="form_row">
          <div class="bg-light p-3">
            <label class="label mb-2">PickUp Date</label>
            <div class="d-flex">
              <div class="input_block">
                <div class="input_hover input-border">
                  <div class="w-100 datepicker-wrap">
                    <input [readonly]="true" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" class="input_fld"
                      placeholder="Select Date" formControlName="pickupDate" />
                    <mat-icon class="date-icon">today</mat-icon>
                    <owl-date-time [pickerType]="'calendar'" #dt1>
                    </owl-date-time>
                  </div>
                </div>
              </div>
            </div>
            &nbsp;

            <label class="label mb-2">Delivery Date</label>
            <div class="d-flex">
              <div class="input_block">
                <div class="input_hover input-border">
                  <div class="w-100 datepicker-wrap">
                    <input [readonly]="true" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" class="input_fld"
                      placeholder="Select Date" formControlName="deliveryDate" />
                    <mat-icon class="date-icon">today</mat-icon>
                    <owl-date-time [pickerType]="'calendar'" #dt2>
                    </owl-date-time>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="name == 'loads' || name == 'all-loads'">
        <label class="label mb-2">Price range</label>
        <div class="form_row">
          <div class="bg-light p-3">
            <div class="min-max-wrap">
              <div class="min-input-box">
                <label class="label mb-2">Min</label>
                <div class="input_block select-border-box-outer">
                  <mat-form-field class="w-100">
                    <input type="number" (keypress)="onCheckEntry($event)" class="input_fld" matInput placeholder=" "
                      formControlName="minPrice" />
                  </mat-form-field>
                </div>
              </div>
              <div class="max-input-box mt-3">
                <label class="label mb-2">Max</label>
                <div class="input_block select-border-box-outer">
                  <mat-form-field class="w-100">
                    <input type="number" (keypress)="onCheckEntry($event)" class="input_fld" matInput placeholder=" "
                      formControlName="maxPrice" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- <div class="slider-outer">
            <mat-slider class="example-margin" [max]="50000" [min]="1" thumbLabel="thumbLabel"></mat-slider>
            <div class="d-flex align-item-center justify-content-between slider-text">
              <span>$0</span>
              <span>$50,000</span>
            </div>
          </div> -->
          </div>
        </div>
      </div>

      <div *ngIf="name == 'loads' || name == 'all-loads'">
        <label class="label mb-2">Trip</label>
        <div class="form_row">
          <div class="bg-light p-3">
            <div>
              <label class="label mb-2">Origin</label>
              <div>
                <div class="input_block select-border-box-outer mb-3">
                  <div class="input_hover input-border">
                    <mat-form-field class="w-100" appearance="fill">
                      <!-- #factorLocation (keypress)="mapAutoPopulateForFactorCompany('factorLocation')" -->
                      <input type="text" class="input_fld" matInput placeholder="Origin" formControlName="origin" />
                    </mat-form-field>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div>
              <label class="label mb-2">Destination</label>
                <div class="input_block select-border-box-outer mb-3">
                  <div class="input_hover input-border">
                    <mat-form-field class="w-100" appearance="fill">
                      <!-- #factorLocation2 (keypress)="mapAutoPopulateForFactorCompany('factorLocation2')" -->
                      <input type="text" class="input_fld" matInput placeholder="Destination"
                        formControlName="destination" />
                    </mat-form-field>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="name == 'all-loads'">
        <label class="label mb-2">Status</label>
        <div class="form_row">
          <div class="bg-light p-3">
            <div>
              <label class="label mb-2">Status Type</label>
              <div class="input_block select-border-box-outer mb-2">
                <mat-form-field class="w-100">
                  <mat-select placeholder="Select Shipment Type" class="select-border-box mt-2"
                    formControlName="shipmentStatus">
                    <mat-option>None</mat-option>
                    <mat-option value="1">Open</mat-option>
                    <mat-option value="2">Active</mat-option>
                    <mat-option value="5">Completed</mat-option>
                    <mat-option value="3">Dispute</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="name == 'loads' || name == 'all-loads'">
        <label class="label mb-2">Equipment</label>
        <div class="form_row">
          <div class="bg-light p-3">
            <div>
              <label class="label mb-2">Service Type</label>
              <div class="input_block select-border-box-outer mb-3">
                <mat-form-field class="w-100">
                  <mat-select placeholder="Select ServiceType" class="select-border-box mt-2"
                    formControlName="loadServiceType">
                    <mat-option>none</mat-option>
                    <mat-option value="1">Full</mat-option>
                    <mat-option value="2">Partial</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div>
              <label class="label mb-2">Pick/Dropoff Type</label>
              <div class="input_block select-border-box-outer mb-3">
                <mat-form-field class="w-100">
                  <mat-select placeholder="Select Type" class="select-border-box mt-2" formControlName="pickupDropoffType">
                    <mat-option>none</mat-option>
                    <mat-option value="1">Single Stop</mat-option>
                    <mat-option value="2">Multiple Stop</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div>
              <label class="label mb-2">Trailer type</label>
              <div class="input_block select-border-box-outer mb-3">
                <mat-form-field class="w-100">
                  <mat-label>Full</mat-label>
                  <mat-select placeholder="Select trailer type" class="select-border-box input_fld"
                    formControlName="trailerTypeId">
                    <mat-option>none</mat-option>
                    <mat-option *ngFor="let iTrailer of _trailerTypes" [value]="iTrailer.id">
                      {{ iTrailer.type }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="">
                <label class="label mb-2">Weight</label>
                <div class="input_hover input-border">
                  <mat-form-field class="w-100" appearance="fill">
                    <input type="number" (keypress)="onCheckEntry($event)" class="input_fld" matInput placeholder=" "
                      formControlName="weight" />
                  </mat-form-field>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="name == 'rfps'">
        <label class="label mb-2">Trip</label>
        <div class="form_row">
          <div class="bg-light p-3">
            <div>
              <label class="label mb-2">Origin</label>
              <div>
                <div class="input_block select-border-box-outer mb-3">
                  <div class="input_hover input-border">
                    <mat-form-field class="w-100" appearance="fill">
                      <!-- #factorLocation (keypress)="mapAutoPopulateForFactorCompany('factorLocation')" -->
                      <input type="text" class="input_fld" matInput placeholder="Origin" formControlName="origin" />
                    </mat-form-field>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div>
              <label class="label mb-2">Destination</label>
              <div>
                <div class="input_block select-border-box-outer mb-3">
                  <div class="input_hover input-border">
                    <mat-form-field class="w-100" appearance="fill">
                      <!-- #factorLocation2 (keypress)="mapAutoPopulateForFactorCompany('factorLocation2')" -->
                      <input type="text" class="input_fld" matInput placeholder="Destination"
                        formControlName="destination" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="name == 'rfps'">
        <label class="label mb-2">Equipment</label>
        <div class="form_row">
          <div class="bg-light p-3">
            <div>
              <label class="label mb-2">Procedure</label>
              <div class="input_block select-border-box-outer mb-2">
                <mat-form-field class="w-100">
                  <mat-select placeholder="Select Type" class="select-border-box" formControlName="procedure">
                    <mat-option value="">Select Procedure</mat-option>
                    <mat-option [value]="procedure.Live_Live">Live/Live
                    </mat-option>
                    <mat-option [value]="procedure.Live_Drop">Live/Drop
                    </mat-option>
                    <mat-option [value]="procedure.Drop_Live">Drop/Live
                    </mat-option>
                    <mat-option [value]="procedure.Drop_Drop">Drop/Drop
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div>
              <label class="label mb-2">Shipment Type</label>
              <div class="input_block select-border-box-outer mb-2">
                <mat-form-field class="w-100">
                  <mat-select placeholder="Select Type" class="select-border-box" formControlName="shipmentType">
                    <mat-option>none</mat-option>
                    <mat-option value="1">Single</mat-option>
                    <mat-option value="2">Multiple</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="name == 'rfps'">
        <label class="label mb-2">Load Details</label>
        <div class="form_row">
          <div class="bg-light p-3">
            <label class="label mb-2">Duration</label>
            <div class="input_block select-border-box-outer mb-2">
              <mat-form-field class="w-100">
                <mat-select placeholder="Select ServiceType" class="select-border-box" formControlName="duration">
                  <mat-option value="">Select RFP Duration</mat-option>
                  <mat-option [value]="rfpDuration.Quarterly">Quarterly
                  </mat-option>
                  <mat-option [value]="rfpDuration.Half_Yearly">Half Yearly
                  </mat-option>
                  <mat-option [value]="rfpDuration.Annually">Annually
                  </mat-option>
                  <mat-option [value]="rfpDuration.Custom">Custom </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            &nbsp;
            <label class="label mb-2">Expiry Date</label>
            <div class="d-flex">
              <div class="input_block">
                <div class="input_hover input-border">
                  <div class="w-100 datepicker-wrap">
                    <input [readonly]="true" [owlDateTime]="dt4" [owlDateTimeTrigger]="dt4" class="input_fld"
                      placeholder="Select Date" formControlName="expiryDate" />
                    <mat-icon class="date-icon">today</mat-icon>
                    <owl-date-time [pickerType]="'calendar'" #dt4>
                    </owl-date-time>
                  </div>
                </div>
              </div>
            </div>
            &nbsp;
            <label class="label mb-2">No. Of Loads</label>
            <div class="input_hover input-border">
              <mat-form-field class="w-100" appearance="fill">
                <input type="number" (keypress)="onCheckEntry($event)" class="input_fld" matInput placeholder=" "
                  formControlName="loads" />
              </mat-form-field>
            </div>
            &nbsp;
            <label class="label mb-2">Total Volume</label>
            <div class="input_hover input-border">
              <mat-form-field class="w-100" appearance="fill">
                <input type="number" (keypress)="onCheckEntry($event)" class="input_fld" matInput placeholder=" "
                  formControlName="volume" />
              </mat-form-field>
            </div>
            &nbsp;
            <label class="label mb-2">Weight</label>
            <div class="input_hover input-border">
              <mat-form-field class="w-100" appearance="fill">
                <input type="number" (keypress)="onCheckEntry($event)" class="input_fld" matInput placeholder=" "
                  formControlName="weight" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="name == 'rfp-open'">
        <label class="label mb-2">RFP</label>
        <div class="form_row">
          <div class="bg-light p-3">
            <div>
              <label class="label mb-2">Duration</label>
              <div class="input_block select-border-box-outer mb-2">
                <mat-form-field class="w-100">
                  <mat-select placeholder="Select ServiceType" class="select-border-box" formControlName="duration">
                    <mat-option value="">Select RFP Duration</mat-option>
                    <mat-option [value]="rfpDuration.Quarterly">Quarterly
                    </mat-option>
                    <mat-option [value]="rfpDuration.Half_Yearly">Half Yearly
                    </mat-option>
                    <mat-option [value]="rfpDuration.Annually">Annually
                    </mat-option>
                    <mat-option [value]="rfpDuration.Custom">Custom
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              &nbsp;
              <label class="label mb-2">Expiry Date</label>
              <div class="d-flex">
                <div class="input_block">
                  <div class="input_hover input-border">
                    <div class="w-100 datepicker-wrap">
                      <input [readonly]="true" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" class="input_fld"
                        placeholder="Select Date" formControlName="expiryDate" />
                      <mat-icon class="date-icon">today</mat-icon>
                      <owl-date-time [pickerType]="'calendar'" #dt3>
                      </owl-date-time>
                    </div>
                  </div>
                </div>
              </div>
              &nbsp;
              <label class="label mb-2">Total Volume</label>
              <div class="input_hover input-border">
                <mat-form-field class="w-100" appearance="fill">
                  <input type="number" (keypress)="onCheckEntry($event)" class="input_fld" matInput placeholder=" "
                    formControlName="volume" />
                </mat-form-field>
              </div>
              &nbsp;
              <label class="label mb-2">No. Of Loads</label>
              <div class="input_hover input-border">
                <mat-form-field class="w-100" appearance="fill">
                  <input type="number" (keypress)="onCheckEntry($event)" class="input_fld" matInput placeholder=" "
                    formControlName="loads" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="name == 'admin'">
        <label class="label mb-2">Status</label>
        <div class="form_row">
          <div class="bg-light p-3">
            <div>
              <label class="label mb-2">Status Type</label>
              <div class="input_block select-border-box-outer mb-2">
                <mat-form-field class="w-100">
                  <mat-select placeholder="Select Status Type" class="select-border-box" formControlName="status">
                    <mat-option>none</mat-option>
                    <mat-option value="1">Accepted</mat-option>
                    <mat-option value="0">In Progress</mat-option>
                    <mat-option value="2">Decline</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="name == 'carrier-list'">
        <!-- <label class="label mb-2">Trip</label> -->
        <div class="form_row">
          <div class="bg-light p-3">
            <div>
              <label class="label mb-2">City</label>
              <div>
                <div class="input_block select-border-box-outer mb-3">
                  <div class="input_hover input-border">
                    <mat-form-field class="w-100" appearance="fill">
                      <input type="text" class="input_fld" matInput placeholder="City" formControlName="city" />
                    </mat-form-field>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div>
              <label class="label mb-2">State</label>
              <div>
                <div class="input_block select-border-box-outer mb-3">
                  <div class="input_hover input-border">
                    <mat-form-field class="w-100" appearance="fill">
                      <input type="text" class="input_fld" matInput placeholder="State" formControlName="state" />
                    </mat-form-field>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div>
              <label class="label mb-2">Member Since</label>
              <div class="d-flex">
                <div class="input_block">
                  <div class="input_hover input-border">
                    <div class="w-100 datepicker-wrap">
                      <input [readonly]="true" [owlDateTime]="dt5" [owlDateTimeTrigger]="dt5" class="input_fld"
                        placeholder="Select Date" formControlName="memberSince" />
                      <mat-icon class="date-icon">today</mat-icon>
                      <owl-date-time [pickerType]="'calendar'" #dt5>
                      </owl-date-time>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            &nbsp; &nbsp;
            <div>
              <label class="label mb-2">Rating</label>
              <div class="d-flex">
                <div class="input_block">
                  <div class="select-rating">
                    <star-rating value="{{ _ratingScore }}" totalstars="{{ _totalstar }}" checkedcolor="#51af57"
                      uncheckedcolor="#ddd" size="24px" readonly="false" (rate)="onChangeRating($event)">
                    </star-rating>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end">
        <button class="btn transparent_btn small_btn mr-3" (click)="close()">
          <i class="material-icons close-filter">close</i>
          Cancel
        </button>
        <button class="rounded_btn green_btn small_btn btn btn_shadow" (click)="applyFilter()">
          <i class="material-icons icon-left">done</i> Apply filter
        </button>
      </div>
    </form>
  </div>
  </div>
</div>