<div class="right_sec">
    <div class="main_inner chat-box-page">
        <div class="chat-area">
            <div class="chat-main-area">
                <div class="chat-header">
                    <div class="back-area">
                        <span class="material-icons" (click)="closeChatDialog()">
                                        keyboard_arrow_left
                                    </span>
                    </div>
                    <div class="d-flex user-pic-name align-items-center">
                        <div class="user-images">
                            <img *ngIf="data.userInfo.profileUrl!==' ' " src={{data.userInfo.profileUrl}} onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" alt="profile-image"/>
                            <img *ngIf="data.userInfo.profileUrl===' ' " src="assets/images/person-ic.jpg" onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';" alt="profile-image"/>
                        </div>
                        <div><strong>{{data.userInfo.company}}</strong>
                            <p class="text-des1cription-888 mb-0">{{data.userInfo.address}}</p>
                        </div>
                    </div>
                    <div class="chat-contract-info-mobile mt-2" *ngIf="showContractDetail">
                        <h3 class="heading3 mb-2">Contract information</h3>
                        <div class="chat-contract-listing">
                            <div class="chat-contract-listing-inner mr-3">
                                <label class="mr-2">Active Contracted :</label>
                                <span>{{data.activeLoads}}</span>
                            </div>
                            <div class="chat-contract-listing-inner">
                                <label class="mr-2">Completed Contracted :</label>
                                <span>{{data.completedLoads}}</span>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div class="chat-inner-box" #scrollMe [scrollTop]="scrolltop" infiniteScroll [infiniteScrollUpDistance]="5"
            (scrolledUp)="onScroll()" [scrollWindow]="false">
            <div class="user-chat" *ngFor='let item of  currentMessageList; index as i;'>
                <div class="chat-date" *ngIf="item?.date">
                    <span>{{item?.date | date:'fullDate'}}</span>
                    <div *ngIf="isLoading" class="mt-4">
                        <h2> Loading...</h2>
                    </div>
                </div>
                <div class="d-flex user-pic-name user-chat" *ngFor="let chats of item.channels">
                    <div class="user-images"><img src={{chats.message?.sender?.profileUrl}}
                            onError="this.onerror=null;this.src='../../../../../../assets/images/person-ic.jpg';"
                            alt=""></div>
                    <div class="chat-text">
                        <div class="d-flex justify-content-between">
                            <strong class="name">{{chats.message?.sender?.company}}
                                <strong *ngIf="chats.message?.sender?.driver"> • {{chats?.message?.sender?.driver}}</strong>
                            </strong>
                            <div class="chat-time">{{chats.message?.dateTime | date:'shortTime'}}</div>
                        </div>
                        <p class="mb-1">{{chats.message?.message}}</p>
                        <div *ngIf="chats.message?.file?.documentUrl && chats.message?.file?.documentType !== 'pdf'" class="upload-img">
                            <img src={{chats.message?.file?.documentUrl}} alt="">
                        </div>
                        <div *ngIf="chats.message?.file?.documentType == 'pdf' || chats?.message?.file?.documentType == 'docx' "
                            class="doc-content mb-1">
                            <div class="doc-item d-flex align-items-center justify-content-between">
                                <div class="doc-info d-flex align-items-center">
                                    <div class="doc-img mr-2">
                                        <span class="material-icons green-text">description</span>
                                    </div>
                                    <div class="d-flex  align-items-center doc-info-text">
                                        <p class="m-0 mr-2">{{chats.message?.file?.documentName}}</p>
                                        <div>
                                            <a href="{{chats.message?.file?.documentUrl}}" class="d-flex">
                                                <i class="material-icons icon-left block-sm download-icon">
                                                    file_download </i></a>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                <div class="check-icon-chat">
                    <div class="chat-time mt-4" *ngIf="chats.message?.sender?.company === sender.company && chats?.actions">
                        <span class="material-icons f-20">done_all</span>
                    </div>
                    <div class="chat-time mt-4" *ngIf="chats.message?.sender?.company === sender.company && !chats?.actions">
                        <span class="material-icons f-20">check</span>
                    </div>
                </div>
                </div>
            </div>
        
                </div>
                <div class="chat-footer align-items-center">
                    <div class="doc-show">
                        <div class="text-with-action-ic">
                            <mat-form-field appearance="outline">
                                <mat-label>Type a new message</mat-label>
                                <input #box matInput (keyup.enter)="sendMessage(box.value); box.value=''" autocomplete="off"
                                    (keyup)="getLength(box.value || this._fileUploaded == true)">
                                <div class="link-box">
                                    <ng-container *ngIf="!_fileUploaded && !_fileUploading">
                                        <div class="red_text">
                                            <ng-container *ngIf="_fileTypeErrorMessage.length>0">
                                                {{_fileTypeErrorMessage}}
        
                                            </ng-container>
                                            <ng-container *ngIf="_fileSizeErrorMessage.length>0">
                                                {{_fileSizeErrorMessage}}
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="_fileUploading; else uploaded">
                                        Uploading documents ...
                                    </ng-container>
        
                                    <ng-template #uploaded>
                                        <ng-container *ngIf="_fileUploaded && !_fileTypeErrorMessage">
                                            <div class="doc-content" (click)="$event.stopPropagation()">
                                                <div class="doc-item d-flex align-items-center justify-content-between">
                                                    <div class="doc-info d-flex align-items-center">
                                                        <div class="doc-img">
                                                            <span class="material-icons green-text mr-2">description</span>
                                                        </div>
                                                        <div class="d-flex  align-items-center doc-info-text">
                                                            <p class="m-0 mr-3">{{_DocumentName}}</p>
                                                            <i class="material-icons" (click)="onRemoveFile()">
                                                                close
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="ml-2">
                        <div class="file-input-field form_row mb-0">
                            <div [ngClass]="{'attached-doc':_fileUploaded, 'error-choose-input':!_DocumentName }"
                                class="choose-input custom-image">
                                <div class="m-0">
                                    <span class="material-icons attach-icon">attach_file</span>
                                </div>
                                <span class="file-active"></span>
                                <input type="file"  #inputFile (change)="onFileSelected($event)" />
                                <input type="hidden" />
                            </div>
                        </div>
                    </div>
                    <div class="btn-send">
                        <button
                            [disabled]="_fileUploading"
                            [className]="applyClass || this._fileUploaded == true ? 'btn rounded_btn large_btn green_btn' : 'btn rounded_btn large_btn cancel_btn'"
                            (click)="sendMessage(box.value); box.value=''">
                            <span class="sm-text-none">
                                Send
                            </span>
                            <i class="material-icons sm-none">
                                send
                            </i>
                        </button>
                    </div>
                </div>
        
            </div>
            <div class="chat-contract-info" *ngIf="showContractDetail">
                <h3 class="heading3">Contract information</h3>
                <div class="chat-contract-listing">
                    <div class="chat-contract-listing-inner">
                        <label>Active Contracted :</label>
                        <span>{{data.activeLoads}}</span>
                    </div>
                    <div class="chat-contract-listing-inner">
                        <label>Completed Contracted :</label>
                        <span>{{data.completedLoads}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>