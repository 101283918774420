import { Injectable } from '@angular/core';
import { TruckModel } from '../models/truck.model';
import { Observable } from 'rxjs';
import { CommonHttpService } from '@app/core/services/common-http.service';

@Injectable({
  providedIn: 'root'
})
export class TruckService {
  private route = 'solodriver/add-vehicle'

  constructor( private commonHttpService: CommonHttpService) { }

  public add(argVehicleModel: TruckModel): Observable<any>{
    return this.commonHttpService.post<TruckModel, TruckModel>(`${this.route}`, argVehicleModel);
  }

  public get(argVehicleId: string): Observable<any> {
    return this.commonHttpService.get<any>(`${'solodriver/vehicle'}/${argVehicleId}`);
  }

  public getVechile(): Observable<any> {
    return this.commonHttpService.get<any>(`${'solodriver/vehicle'}`);
  }

  public update(argVehicleModel: TruckModel): Observable<any>{
    return this.commonHttpService.put<TruckModel, TruckModel>(`${'solodriver/vehicle'}/${argVehicleModel.id}`, argVehicleModel);
  }

  public delete(argVehicleId: string): Observable<any>{
    return this.commonHttpService.delete(`${'solodriver/vehicle'}/${argVehicleId}`);
  }
}
