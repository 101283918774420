import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '@app/core/services/common.service';
import { LoadService } from '@app/modules/shipper/services/load.service';
import { StarRatingComponent } from 'ng-starrating';

@Component({
  selector: 'app-carrier-profile',
  templateUrl: './carrier-profile.component.html',
  styleUrls: ['./carrier-profile.component.scss']
})
export class CarrierProfileComponent implements OnInit {
  //#region members
  @Input() carrierInfo: any;
  @Input() bidRate: any;
  @Output() MarkFav = new EventEmitter<boolean>();

  @ViewChild('ratingChange') ratingChange: StarRatingComponent;
  _readonly: boolean;
  totalstar = 5;
  //#endregion

  //#region Life cycle hooks 
  constructor(
    private _loadService: LoadService,
    private _commonService:CommonService
  ) {
   
  }

  ngOnInit(): void {
  }




  //#endregion

  //#region events
  onMarkFavourite(argCarrier){
    const markFavModel = new MarkFavouriteCarrierModel();
    markFavModel.carrierId = argCarrier.id;
    this._loadService.markFavourite(markFavModel).subscribe(res=>{
      if(res.statusCode == 200 || res.statusCode == 201){
        //do something
        if(argCarrier.isFavorite){
          argCarrier.isFavorite = false;
        }
        else{
          argCarrier.isFavorite = true;
        }
      }
      else{
        this._commonService.showSnakeBar(res.message);
      }
    }, (error) => {
      this._commonService.showSnakeBar(error.message);
    })
  }
 
  //#endregion

}

export class MarkFavouriteCarrierModel{
  carrierId: number = undefined;
}