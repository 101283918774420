import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reference-number-popup',
  templateUrl: './reference-number-popup.component.html',
  styleUrls: ['./reference-number-popup.component.scss']
})
export class ReferenceNumberPopupComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  close(): void {
    this.dialog.closeAll();
  }
}
