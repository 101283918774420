import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-offer-confirmation',
  templateUrl: './offer-confirmation.component.html',
  styleUrls: ['./offer-confirmation.component.scss']
})
export class OfferConfirmationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OfferConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router) {
     
     }
    

  ngOnInit(): void {
  }
  
  closeDialog() {
    this.dialogRef.close();
    this._router.navigate(['solo-driver/assigned/loads']);
  }

}
